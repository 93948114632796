@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url('./assets/Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url('./assets/Avenir-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  src: url('./assets/Avenir-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Helio';
  font-weight: 400;
  src: url('./assets/helio.ttf') format('truetype');
}

body {
  background-color: #02161F;
}

.app {
  background-color: #02161F;
}

.mainApp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
}

.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000000;
  background-color: #02161F;
}

.spinnerContainer {
  position: fixed;
  top: 80vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000001;
  opacity: 0.5;
}

.serFutbolLoader {
  height: 15vh;
  width: auto;
  z-index: 100000001;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-color: #011B28;
  filter: brightness(80%);
  object-position: 0 100%;
}

.foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 0 100%;
}

.logo {
  width: 7vw;
  height: auto;
  position: fixed;
  top: 2.5vw;
  left: 2.5vw;
  opacity: 0.7;
  z-index: 1000000;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -50%);
}

.headingMain {
  width: 60vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -50%);
}

.headingFirst {
  height: 15vh;
  width: auto;
  margin-right: 2.5vw;
}

.headingSecond {
  height: 15vh;
  width: auto;
}

.cta {
  font-family: 'Avenir';
  font-weight: 900;
  font-size: 1.5vw;
  color: #fff;
  border: 1.5px solid #fff;
  padding-top: 1.7vh;
  padding-bottom: 1.7vh;
  padding-right: 4vw;
  padding-left: 4vw;
  width: auto;
  height: auto;
  position: absolute;
  top: 87vh;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.cta:hover {
  border: 1.5px solid #fff;
  background-color: #fff;
  color: #016766;
}

.scrollIconContainer {
  position: absolute;
  top: 87vh;
  left: 6vw;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto; 
  pointer-events: none;
}

.instagramLink {
  width: auto;
  height: 6vh;
  position: absolute;
  opacity: 0.8;
  top: 87vh;
  right: 2.5vw;
  transform: translate(0, -50%);
  transition: all 0.5s ease-out;
}

.instagramLink:hover {
  transform: translate(0, -50%) rotate(10deg);
  opacity: 1;
}

.fieldsAuxContainer {
  position: absolute;
  top: 25vh;
  left: 0;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
}

.fieldsTitle {
  font-family: 'Helio';
  font-weight: 400;
  font-size: 2.1vw;
  color: #e1e1e1;
  margin-left: 2.5vw;
  line-height: 2.8vw;
}

.fieldsTitleContrast {
  color: #2C5861;
}

.fieldsSubtitle {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  color: #e1e1e1;
  opacity: 0.7;
  margin-right: 2.5vw;
  text-align: right;
}

.fieldsContainer {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.mainContainer {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: transparent;
  z-index: 100000;
}

.crowdImg {
  width: 100vw;
  height: auto;
  object-fit: cover;
}

.aboutContainer {
  background: linear-gradient(to bottom, #012D37, #2B545D);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.aboutImg {
  height: 90vh;
  margin-top: 5vh;
  width: auto;
  object-fit: cover;
  margin-left: 2.5vw;
}

.aboutTextContainer {
  width: 50vw;
  margin-right: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10vh;
}

.aboutTextTitle {
  font-family: 'Helio';
  font-weight: 400;
  font-size: 3.8vw;
  color: #02161F;
  margin-bottom: 4vh;
}

.aboutTextTitleContrast {
  color: #f5f5f5;
}

.aboutText {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.2vw;
  color: #e1e1e1;
  text-align: justify;
  line-height: 3.2vw;
  width: 100%;
}

.aboutTick {
  width: 1.2vw;
  height: auto;
  margin-right: 1.5vw;
}

.aboutTextSwitch {
  margin-top: 5vh;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.4vw;
  color: #fff;
  line-height: 3.2vw;
  width: 100%;
  text-align: right;
  font-style: italic;
}

.grassContainer {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.grassImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  background: linear-gradient(to bottom, #2B545D, #02161F, #02161F);
}

.grassText {
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.6vw;
  color: #e1e1e1;
  text-align: center;
  line-height: 4vw;
  width: 85vw;
  position: absolute;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logosContainer {
  position: absolute;
  bottom: 10vh;
  right: 2.5vw;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grassLogo {
  width: 10vw;
  height: auto;
  margin-left: 2vw;
  object-fit: cover;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
}

.contactInfo {
  height: 100vh;
  width: 45vw;
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contactBg {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 45vw;
  object-fit: cover;
}

.contactInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-left: 5vw;
}

.firstRow {
  margin-bottom: 13vh;
}

.contactInstagram {
  width: auto;
  height: 7.5vh;
  opacity: 0.8;
  transition: all 0.5s ease-out;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.contactInstagram:hover {
  transform: scale(1.1);
  transform-origin: left;
  opacity: 1;
}

.instagramName {
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 4vw;
  color: #fff;
  margin-left: 2vw;
  z-index: 3000000000;
  text-decoration: none;
}

.altInstagramName {
  font-size: 5vw;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 55vw;
  height: 100vh;
}

.formAux {
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.8vw;
  color: #fff;
  text-align: right;
  width: 100%;
  margin-bottom: 10vh;
  margin-right: 2.5vw;
}

.formInputSimple {
  color: #fff;
  background-color: transparent;
  border-bottom: 1px solid rgba(255,255,255,0.8);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 1.1vw;
  margin-top: 0;
  margin-bottom: 4vh;
  height: 2vw;
  width: 80%;
  padding-right: 1vw;
  padding-left: 0.5vw;
  margin-right: 2.5vw;
}

.formInputSimple::placeholder {
  color: rgba(255, 255, 255, 1);
}

.formInputSimple:focus {
  outline: none;
}

.formInputNoPlaceholder {
  padding-left: 0;
  color: rgba(255, 255, 255, 1) !important;
}

.formTextArea {
  color: #fff;
  background-color: transparent;
  border-bottom: 1px solid rgba(255,255,255,0.8);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 1.7vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 25vh;
  width: 80%;
  padding-right: 1vw;
  padding-left: 0.5vw;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 2.5vw;
  resize: none;
}

.formTextArea::placeholder {
  color: rgba(255, 255, 255, 1);
}

.formTextArea:focus {
  outline: none;
}

.formButton {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 35px;
  color: #fff;
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  margin-top: 2vh;
  cursor: pointer;
  margin-right: 2.5vw;
}

.formButton:hover {
  opacity: 0.9;
}

.formButton:focus {
  outline: none;
}

.formButton:disabled {
  cursor: default;
  opacity: 0.6;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 100000;
}

.footerText {
  color: #fff;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 0;
}

.footerLeft {
  margin-left: 2.5vw;
}

.footerRight {
  margin-right: 2.5vw;
}

.logoFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: auto;
}

.logoFooter {
  height: 40%;
  width: auto;
  margin-left: 0.3vw;
  cursor: pointer;
  margin-bottom: 0.5vh;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  .serFutbolLoader {
    height: auto;
    width: 70vw;
    left: 50%;
    transform: translate(-50%, -150%);
  }
  
  .background {
    width: 100vw;
    height: 100vh;
    object-position: 50% 100%;
  }
  
  .foreground {
    width: 100vw;
    height: 100vh;
    object-position: 50% 100%;
  }
  
  .contactBg {
    width: 100vw;
    height: 100vh;
    background-color: #02161F;
  }
  
  .logo {
    width: 10vw;
  }
  
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%);
  }

  .headingMain {
    width: 80vw;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -150%);
  }
  
  .headingFirst {
    height: 7vh;
    width: auto;
    margin-right: 3vw;
  }
  
  .headingSecond {
    height: 7vh;
    width: auto;
  }
  
  .cta {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 3vw;
    color: #fff;
    border: 1.5px solid #fff;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
    padding-right: 6vw;
    padding-left: 6vw;
    top: 84vh;
    transform: translate(-50%, -50%);
  }

  .scrollIconContainer {
    top: 84vh;
    left: 8.5vw;
    transform: translate(-50%, -50%);
  }
  
  .instagramLink {
    top: 84vh;
    height: 4vh;
    right: 8.5vw;
    transform: translate(50%, -50%);
  }
  
  .fieldsAuxContainer {
    position: absolute;
    top: 2vh;
    left: 0;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 500;
  }
  
  .fieldsTitle {
    font-family: 'Helio';
    font-weight: 400;
    font-size: 4.2vw;
    color: #e1e1e1;
    margin-left: 5vw;
    line-height: 5vw;
  }
  
  .fieldsTitleContrast {
    color: #2C5861;
  }
  
  .fieldsSubtitle {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1.8vw;
    color: #e1e1e1;
    opacity: 0.7;
    margin-right: 2.5vw;
    text-align: right;
  }

  .fieldsContainer {
    height: 65vh;
    width: 100vw;
    position: relative;
  }
  
  .mainContainer {
    overscroll-behavior-y: contain;
    margin: 0;
    padding: 0;
    height: 65vh;
    width: 100vw;
    user-select: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 400;
  }
  
  .crowdImg {
    width: 100vw;
    height: auto;
    object-fit: cover;
  }
  
  .aboutBg {
    width: 100vw;
    height: 150vh;
    background: linear-gradient(to bottom, #012D37, #2B545D);
    margin-top: -30vh;
  }
  
  .aboutContainer {
    width: 100vw;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -5vh;
  }
  
  .aboutImg {
    height: 30vh;
    width: auto;
    object-fit: cover;
    margin-left: 10vw;
    align-self: flex-start;
  }
  
  .aboutTextContainer {
    width: 80vw;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10vh;
  }
  
  .aboutTextTitle {
    font-family: 'Helio';
    font-weight: 400;
    font-size: 7.5vw;
    color: #02161F;
    margin-bottom: 2vh;
  }
  
  .aboutTextTitleContrast {
    color: #f5f5f5;
  }
  
  .aboutText {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3vw;
    color: #e1e1e1;
    text-align: justify;
    line-height: 7vw;
    width: 100%;
  }

  .aboutTick {
    width: 2.5vw;
    height: auto;
    margin-right: 1.5vw;
  }
  
  .aboutTextSwitch {
    margin-top: 5vh;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.2vw;
    color: #fff;
    line-height: 3.2vw;
    width: 100%;
    text-align: right;
  }
  
  .grassImg {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background: linear-gradient(to bottom, #2B545D, #02161F, #02161F);
  }
  
  .grassText {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 2.4vw;
    color: #e1e1e1;
    text-align: center;
    line-height: 7vw;
    width: 90vw;
    position: absolute;
    top: 40vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .logosContainer {
    position: absolute;
    bottom: 15vh;
    right: 2.5vw;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .grassLogo {
    width: 18vw;
    height: auto;
    margin-left: 2vw;
    object-fit: cover;
  }

  .contactContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .contactInfo {
    height: auto;
    width: 80vw;
    position: relative;
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }
  
  .contactBg {
    display: none;
  }
  
  .contactInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin-left: 0;
  }
  
  .firstRow {
    margin-bottom: 0;
  }
  
  .contactInstagram {
    width: auto;
    height: 5vh;
    opacity: 0.8;
    transition: all 0.5s ease-out;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .contactInstagram:hover {
    transform: scale(1.1);
    transform-origin: left;
    opacity: 1;
  }
  
  .instagramName {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 4vw;
    color: #fff;
    margin-left: 2vw;
    z-index: 3000000000;
    text-decoration: none;
  }
  
  .altInstagramName {
    font-size: 4vw;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 85vh;
  }
  
  .formAux {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 2.4vw;
    color: #fff;
    text-align: center;
    width: 80vw;
    margin-bottom: 10vh;
  }
  
  .formInputSimple {
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.8);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.4vw;
    line-height: 1.4vw;
    margin-top: 0;
    margin-bottom: 4vh;
    height: 3vw;
    width: 80%;
    padding-right: 1vw;
  }
  
  .formInputSimple::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .formInputSimple:focus {
    outline: none;
  }
  
  .formTextArea {
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.8);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.4vw;
    line-height: 2vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 25vh;
    width: 80%;
    padding-right: 1vw;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
  }
  
  .formTextArea::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .formTextArea:focus {
    outline: none;
  }
  
  .formButton {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 35px;
    background-color: #016766;
    color: #fff;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 14px;
    border: none;
    border-radius: 30px;
    margin-top: 2vh;
    cursor: pointer;
    margin-right: calc(12.5% - 0.5vw);
  }
  
  .formButton:hover {
    opacity: 0.9;
  }
  
  .formButton:focus {
    outline: none;
  }
  
  .formButton:disabled {
    cursor: default;
    opacity: 0.6;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index: 100000;
  }
  
  .footerText {
    color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .footerLeft {
    margin-left: 2.5vw;
  }
  
  .footerRight {
    margin-right: 2.5vw;
  }
  
  .logoFooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: auto;
  }
  
  .logoFooter {
    height: 40%;
    width: auto;
    margin-left: 0.3vw;
    cursor: pointer;
    margin-bottom: 0.5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .serFutbolLoader {
    height: auto;
    width: 70vw;
    transform: translate(-50%, -150%);
  }

  .background {
    width: 100vw;
    height: 85vh;
    object-position: 50% 100%;
  }
  
  .foreground {
    width: 100vw;
    height: 85vh;
    object-position: 50% 100%;
  }

  .logo {
    width: 20vw;
  }
  
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .headingMain {
    width: 90vw;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -150%);
  }
  
  .headingFirst {
    height: 5.5vh;
    width: auto;
    margin-right: 3vw;
  }
  
  .headingSecond {
    height: 5.5vh;
    width: auto;
  }
  
  .cta {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 5vw;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    border: 1.5px solid #fff;
    padding-top: 1.8vh;
    padding-bottom: 1.8vh;
    padding-right: 8vw;
    padding-left: 8vw;
    width: 70vw;
    top: 75vh;
    transform: translate(-50%, -50%);
  }

  .scrollIconContainer {
    display: none;
  }
  
  .instagramLink {
    top: 6.5vw;
    height: 6vh;
    width: auto;
    right: 12.5vw;
    transform: translate(50%, 0);
  }
  
  .fieldsAuxContainer {
    position: absolute;
    top: 5vh;
    left: 0;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 500;
  }
  
  .fieldsTitle {
    font-family: 'Helio';
    font-weight: 400;
    font-size: 6vw;
    color: #e1e1e1;
    margin-left: 5vw;
    line-height: 5vw;
  }
  
  .fieldsTitleContrast {
    color: #2C5861;
  }
  
  .fieldsSubtitle {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 2.7vw;
    color: #e1e1e1;
    opacity: 0.7;
    margin-right: 2.5vw;
    text-align: right;
  }
  
  .mainContainer {
    overscroll-behavior-y: contain;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    user-select: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 400;
  }
  
  .crowdImg {
    width: 100vw;
    height: auto;
    object-fit: cover;
  }
  
  .aboutBg {
    width: 100vw;
    height: 150vh;
    background: linear-gradient(to bottom, #012D37, #2B545D);
    margin-top: -30vh;
  }
  
  .aboutContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .aboutImg {
    height: 30vh;
    width: auto;
    object-fit: cover;
    margin-left: 10vw;
    align-self: flex-start;
  }
  
  .aboutTextContainer {
    width: 80vw;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10vh;
  }
  
  .aboutTextTitle {
    font-family: 'Helio';
    font-weight: 400;
    font-size: 8vw;
    color: #02161F;
    margin-bottom: 2vh;
  }
  
  .aboutTextTitleContrast {
    color: #f5f5f5;
  }
  
  .aboutText {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.5vw;
    color: #e1e1e1;
    text-align: justify;
    line-height: 7vw;
    width: 100%;
  }

  .aboutTick {
    width: 3.5vw;
    height: auto;
    margin-right: 1.5vw;
  }
  
  .aboutTextSwitch {
    margin-top: 5vh;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.5vw;
    color: #fff;
    line-height: 3.2vw;
    width: 100%;
    text-align: right;
  }
  
  .grassImg {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background: linear-gradient(to bottom, #2B545D, #02161F, #02161F);
  }
  
  .grassText {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 3.5vw;
    color: #e1e1e1;
    text-align: center;
    line-height: 7vw;
    width: 90vw;
    position: absolute;
    top: 40vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .logosContainer {
    position: absolute;
    bottom: 15vh;
    right: 2.5vw;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .grassLogo {
    width: 25vw;
    height: auto;
    margin-left: 2vw;
    object-fit: cover;
  }

  .contactContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .contactInfo {
    height: auto;
    width: 90vw;
    position: relative;
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }
  
  .contactBg {
    display: none;
  }
  
  .contactInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin-left: 0;
  }
  
  .firstRow {
    margin-bottom: 0;
  }
  
  .contactInstagram {
    width: auto;
    height: 5vh;
    opacity: 0.8;
    transition: all 0.5s ease-out;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .contactInstagram:hover {
    transform: scale(1.1);
    transform-origin: left;
    opacity: 1;
  }
  
  .instagramName {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 5vw;
    color: #fff;
    margin-left: 3vw;
    z-index: 3000000000;
    text-decoration: none;
  }
  
  .altInstagramName {
    font-size: 5vw;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 110vh;
  }
  
  .formAux {
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 4.5vw;
    color: #fff;
    text-align: center;
    width: 90vw;
    margin-bottom: 10vh;
  }
  
  .formInputSimple {
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.8);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 4vw;
    line-height: 4vw;
    margin-top: 0;
    margin-bottom: 4vh;
    height: 8vw;
    width: 95%;
    padding-right: 1vw;
  }
  
  .formInputSimple::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .formInputSimple:focus {
    outline: none;
  }
  
  .formTextArea {
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.8);
    border-right: none;
    border-top: none;
    border-left: none;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 4vw;
    line-height: 5vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 30vh;
    width: 95%;
    padding-right: 1vw;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
  }
  
  .formTextArea::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .formTextArea:focus {
    outline: none;
  }
  
  .formButton {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 35px;
    background-color: #016766;
    color: #fff;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 14px;
    border: none;
    border-radius: 30px;
    margin-top: 2vh;
    cursor: pointer;
    margin-right: calc(2.5%);
  }
  
  .formButton:hover {
    opacity: 0.9;
  }
  
  .formButton:focus {
    outline: none;
  }
  
  .formButton:disabled {
    cursor: default;
    opacity: 0.6;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index: 100000;
  }
  
  .footerText {
    color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .footerLeft {
    margin-left: 2.5vw;
  }
  
  .footerRight {
    margin-right: 2.5vw;
  }
  
  .logoFooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: auto;
  }
  
  .logoFooter {
    height: 40%;
    width: auto;
    margin-left: 0.3vw;
    cursor: pointer;
    margin-bottom: 0.5vh;
  }
}