.cardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.c {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
}

.front,
.back {
  background-size: cover;
}

.backImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(60%);
}

.backTitle {
  font-family: 'Avenir';
  font-weight: 900;
  font-size: 3vw;
  color: #fff;
  position: absolute;
  bottom: 2vw;
  right: 2vw;
  margin-top: 0;
  margin-bottom: 0;
}

.front {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(25, 49, 65,0.3);
}

.frontImg {
  height: 100%;
  width: 50%;
  object-fit: cover;
  pointer-events: none;
}

.frontInfoContainer {
  height: 90%;
  width: 40%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.frontInfoTitle {
  font-family: 'Avenir';
  font-weight: 900;
  font-size: 3vw;
  color: #e1e1e1;
  margin-top: 0;
  margin-bottom: 2vh;
}

.frontInfoMain {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.3vw;
  color: #e1e1e1;
  margin-top: 0;
  margin-bottom: 3vh;
  line-height: 3vw;
}

.frontInfoAddress {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.2vw;
  color: rgba(225, 225, 225, 0.7);
  margin-top: 0;
  margin-bottom: 3vh;
  line-height: 2.5vw;
}

.frontInfoCTA {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.6vw;
  color: #e1e1e1;
  margin-top: 0;
  margin-bottom: 0;
  align-self: flex-end;
  cursor: pointer;
}

.alquilaApp {
  height: 3vh;
  width: auto;
  object-fit: cover;
  margin-right: 0.5vw;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  .backTitle {
    font-size: 3.5vw;
  }
  
  .front {
    flex-direction: row;
  }
  
  .frontImg {
    height: 100%;
    width: 50%;
    object-fit: cover;
  }
  
  .frontInfoContainer {
    height: 100%;
    width: 50%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .frontInfoTitle {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 3vw;
    color: #e1e1e1;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  
  .frontInfoMain {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1.6vw;
    color: #e1e1e1;
    margin-top: 0;
    margin-bottom: 3vh;
    line-height: 3vw;
  }

  .frontInfoAddress {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1.6vw;
    color: rgba(225, 225, 225, 0.7);
    margin-top: 0;
    margin-bottom: 3vh;
    line-height: 2.5vw;
    cursor: pointer;
  }
  
  .frontInfoCTA {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 2vw;
    color: #e1e1e1;
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-end;
    cursor: pointer;
    pointer-events: all;
  }

  .alquilaApp {
    height: 2vh;
    width: auto;
    object-fit: cover;
    margin-right: 1vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .backTitle {
    font-size: 6vw;
  }
  
  .front {
    flex-direction: column;
  }
  
  .frontImg {
    height: 50%;
    width: 100%;
    object-fit: cover;
  }
  
  .frontInfoContainer {
    height: 50%;
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .frontInfoTitle {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 5vw;
    color: #e1e1e1;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .frontInfoMain {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3vw;
    color: #e1e1e1;
    margin-top: 0;
    margin-bottom: 3vh;
    line-height: 4.5vw;
  }

  .frontInfoAddress {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3vw;
    color: rgba(225, 225, 225, 0.7);
    margin-top: 0;
    margin-bottom: 3vh;
    line-height: 4vw;
  }
  
  .frontInfoCTA {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 4vw;
    color: #e1e1e1;
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-end;
    cursor: pointer;
    pointer-events: all;
  }

  .alquilaApp {
    height: 2vh;
    width: auto;
    object-fit: cover;
    margin-right: 2vw;
  }
}