* {
  box-sizing: border-box;
}

.subContainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.subContainer > div {
  position: absolute;
  width: 70vw;
  height: 70vh;
  top: 15vh;
  left: 15vw;
  will-change: transform;
  z-index: 500001;
}

.subContainer > div > div {
  width: 100%;
  height: 100%;
  will-change: transform;
  box-shadow: 0 20.5px 125px -25px rgba(50, 50, 73, 0.5), 0 15.5px 75px -37.5px rgba(0, 0, 0, 0.6);
  background-color: rgb(10, 26, 36, 0.8);
}

.chevronRight {
  position: absolute;
  z-index: 500000;
  cursor: pointer;
  opacity: 0.3;
  top: 50%;
  right: 2.5vw;
  transform: translate(0, -50%);
}

.chevronLeft {
  position: absolute;
  z-index: 500000;
  cursor: pointer;
  opacity: 0.3;
  top: 50%;
  left: 2.5vw;
  transform: translate(0, -50%) rotate(180deg);
}

.chevronRight:hover, .chevronLeft:hover {
  opacity: 0.7;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  .subContainer {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 65vh;
  }
  
  .subContainer > div {
    position: absolute;
    width: 70vw;
    height: 35vh;
    top: 17.5vh;
    left: 15vw;
    will-change: transform;
    z-index: 500001;
  }
  
  .subContainer > div > div {
    width: 100%;
    height: 100%;
    will-change: transform;
    box-shadow: 0 20.5px 125px -25px rgba(50, 50, 73, 0.5), 0 15.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    background-color: rgb(10, 26, 36, 0.8);
  }
  
  .chevronRight {
    position: absolute;
    z-index: 500000;
    cursor: pointer;
    opacity: 0.3;
    top: 50%;
    right: 2.5vw;
    transform: translate(0, -50%);
  }
  
  .chevronLeft {
    position: absolute;
    z-index: 500000;
    cursor: pointer;
    opacity: 0.3;
    top: 50%;
    left: 2.5vw;
    transform: translate(0, -50%) rotate(180deg);
  }
  
  .chevronRight:hover, .chevronLeft:hover {
    opacity: 0.7;
  }
}